.related {
  background-color: #eff7f2;
  padding: 64px 0;
}

.title {
  margin: 0;
  color: #474747;
  font-family: "AndesBold";
  font-weight: normal;
  font-size: 24px;
  margin-bottom: 16px;
  text-align: center;
}

.relatedGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  grid-gap: 16px;
}
