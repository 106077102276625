@keyframes rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.wrap {
  background: rgb(255, 254, 253);
  overflow: hidden;
}

.stories {
  padding: 40px 0;
  z-index: 1;
  background: #eeeeee;
}

.storyBox {
  display: block;
  padding: 32px 0;
  text-decoration: none;
  color: inherit;
  transition: 0.2s ease-out;
  margin-bottom: 32px;

  transition: 0.5s ease-out;
  background: transparent;
  display: grid;
  grid-template-columns: 1fr 130px;
  grid-gap: 32px;
  align-items: center;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #fff;
  /* margin-left: auto;
  margin-right: auto; */
}

.stor .storyBox:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--key-color);
  opacity: 0.01;
  border-radius: 8px;
  transition: 0.5s ease-out;
}

.stories > div:nth-child(even) .storyBox {
  grid-template-columns: 130px 1fr;
  margin-left: auto;
}

.stories > div:nth-child(even) .storyBox > div:first-child {
  grid-column: 2;
}

.stories > div:nth-child(even) .storyBox .storyImage {
  grid-row: 1;
  grid-column: 1;
}

.stories > div:nth-child(even) .storyBox .bottomWrap,
.stories > div:nth-child(even) .storyBox .topWrap {
  left: auto;
  right: 0;
}

.storyBox:hover {
  color: inherit;
  text-decoration: none;
}

.storyBox:hover:after {
  /* opacity: 0.05; */
}

.storyTitle {
  font-family: AndesBold;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 1.2;
  margin: 0;
  padding-bottom: 13px;
  margin-bottom: 16px;
  position: relative;
}

.storyTitle:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 3px;
  background: var(--key-color);
  width: 32px;
  border-radius: 1.5px;
  transform-origin: center left;
  transition: 0.5s ease-out;
}

.storyIntro {
  font-size: 16px;
  letter-spacing: -0.35px;
  line-height: 1.5;
}

.storyImage {
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 200px;
  transition: 0.5s ease-out;
  z-index: -1;
  transition: 0.75s ease-out;
  clip-path: circle(100px at center);
  background: var(--bg-color);
}

.bottomWrap,
.topWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
}

.topWrap img,
.bottomWrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 520px;
  height: 520px;
  max-width: none;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.bottomWrap {
  z-index: -1;
}

.bottomWrap:after {
  content: "";
  display: block;
  position: absolute;
  top: -100vw;
  left: -100vw;
  right: -100vw;
  bottom: -100vw;
  background: var(--bg-color);
  border-radius: 50%;
  z-index: -1;
  transition: 1s ease-out;
  pointer-events: none;
}

.storyBox:hover .bottomWrap {
  clip-path: circle(200px at center);
}

.storyBox:hover .storyImage:after {
  transform: scale(2);
}

.storyBox:hover .storyTitle:after {
  transform: scaleX(2);
}

.onStoryPage .storySelected {
  z-index: 999;
}

.onStoryPage .storySelected .bottomWrap {
  opacity: 1 !important;
  clip-path: circle(100vw at center) !important;
  /* z-index: 1; */
}

.storyPage {
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s 0.5s ease-out;
  transform: translateY(50%);
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.storyPageHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50vh;
  background-color: var(--bg-color);
}

.storyPageHeader img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80%;
  transform: translate(-50%, -50%);
}

.storyPageContent {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  /* text-align: center; */
  padding: 64px;
}

.storyPageTitle {
  margin: 0;
  font-family: Andes;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 42px;
}

.storyPageHeader img,
.storyPageTitle {
  transition: 0.5s 1s ease-out;
  opacity: 0;
  /* transform: translateY(10px); */
}

.visible .storyPageHeader img,
.visible .storyPageTitle {
  opacity: 1;
  /* transform: translateY(0%); */
}

@media (min-width: 480px) {
  .storyBox {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }

  .stories > div:nth-child(even) .storyBox {
    grid-template-columns: 1fr 1fr;
  }

  .storyTitle {
    font-size: 24px;
  }

  .storyIntro {
    font-size: 18px;
  }
}
