.wrap {
  display: block;
  border: 1px solid rgba(166, 208, 180, 0.2);
  background-color: #ffffff;
  padding: 24px;
  text-align: center;
  grid-template-rows: max-content;
  justify-content: flex-start;
  transition: 0.3s ease-out;
}

.wrap,
.wrap:hover,
.wrap:active,
.wrap:visited {
  text-decoration: none;
  color: inherit;
}

.wrap:hover {
  box-shadow: 0 0 4px 3px rgba(166, 208, 180, 0.2);
}

.wrap:active {
  background: #f6faf8;
}

.icon {
  width: 56px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  position: relative;
  z-index: 1;
}

.icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: #fffbed;
  z-index: -1;
  transition: 0.2s ease-out;
}

.icon > * {
  display: block;
}

.wrap:hover .icon:before {
  transform: scale(1.3);
}

.title {
  font-size: 16px;
  line-height: 1.25;
  font-weight: bold;
  letter-spacing: -0.3px;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.description {
  margin: 0;
  font-size: 16px;
}

.alternate .title {
  font-weight: normal;
}

.alternate .description {
  font-weight: bold;
}
