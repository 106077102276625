.stories {
  padding: 0px 0;
  z-index: 1;
  background: #fff;
  display: grid;
}

.story {
  background: #fff;
  position: relative;
}

.gridded {
  padding: 0;
}

.gridded .story:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color);
  opacity: 0.4;
}

@media (min-width: 650px) {
  .stories {
    padding: 40px 0;
  }

  .gridded {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
}
