@keyframes rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.header {
  margin: 0;
  text-align: center;
  padding: 24px 0 0 0;
  background: #fffbed;
  position: relative;
}

.headerText {
  margin: 0;
  font-size: 34px;
  line-height: 1.2;
  font-family: "AndesBold";
  font-weight: normal;
}

.headerImageWrap {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  z-index: 5;
  overflow: hidden;
}

.headerImageWrap:before,
.headerImageWrap:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 3px;
  width: 6px;
  height: 6px;
  background: #222;
  border-radius: 50%;
  transform: translate(-50%, 0%);
}

.headerImageWrap:after {
  left: auto;
  right: -3px;
}

.headerImageInner {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 3px;
  overflow: hidden;
  border-bottom: 1px solid #222;
}

.headerImage {
  animation: rotate 120s infinite linear;
  z-index: -1;
  max-width: 100%;
  max-height: 200%;
}

.intro {
  font-size: 16px;
  text-align: center;
  padding: 40px 0;
  background: #fffbed;
}

.preTitle {
  font-size: 0.5em;
  line-height: 1;
}

@media (min-width: 600px) {
  .headerText {
    font-size: 54px;
  }

  .intro {
    font-size: 20px;
  }
}
