.wrap {
  background: #fcf9c4;
  text-align: center;
  padding: 8px;
  color: #474747;
  text-decoration: none;
  font: inherit;
  display: block;
  font-weight: bold;
  letter-spacing: -0.3px;
  transition: 0.3s ease-out;
  font-size: 14px;
}

.wrap:hover {
  color: #474747;
  text-decoration: none;
  background: #fae6a3;
}

.wrap:active {
  background: #f7da74;
}

@media (min-width: 480px) {
  .wrap {
    font-size: 16px;
  }
}
