.storyBox {
  display: block;
  padding: 32px 0;
  text-decoration: none;
  color: inherit;
  transition: 0.5s ease-out;
  position: relative;
  z-index: 1;
}

.storyBox:hover {
  color: inherit;
  text-decoration: none;
}

.inner {
  display: grid;
  grid-gap: 24px;
  align-items: center;
}

.storyTitle {
  font-family: AndesBold;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
  padding-bottom: 13px;
  margin-bottom: 16px;
  position: relative;
}

.storyTitle:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 3px;
  background: var(--color);
  width: 48px;
  border-radius: 1.5px;
  transform-origin: center left;
  transition: 0.5s ease-out;
}

.storyImage {
  height: 200px;
  position: relative;
}

.storyImage > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.imageWrap {
  height: 400px;
  width: 400px;
  position: relative;
  pointer-events: none;
}

.backCrop,
.front,
.back {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 426px;
  height: 240px;
}

.backCrop {
  clip-path: circle(80px);
  transition: 0.5s ease-out;
  height: 426px;
  background: var(--color);
}

.icon {
  margin-bottom: 24px;
}

.backCrop:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color);
  opacity: 0.01;
  z-index: -1;
}

.storyBox:hover .backCrop {
  clip-path: circle(110px);
}

.storyBox:hover .storyTitle:after {
  width: 64px;
}

@media (min-width: 480px) {
  .inner {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }

  .reversed .storyImage {
    grid-row: 1;
    grid-column: 2;
  }

  .block .inner {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 650px) {
  .storyTitle {
    font-size: 24px;
  }

  .storyIntro {
    font-size: 18px;
  }

  .backCrop {
    clip-path: circle(100px);
  }

  .storyBox:hover .backCrop {
    clip-path: circle(150px);
  }

  .block .inner {
    grid-template-columns: 1fr;
    margin-right: auto;
    margin-left: 0;
    max-width: 400px;
  }

  .block.reversed .inner {
    margin-left: auto;
    margin-right: 0;
  }
}

.block.reversed,
.block {
  grid-template-columns: 1fr;
  grid-gap: 24px;
}

.block.reversed .storyImage {
  grid-row: auto;
  grid-column: auto;
}

.block .inner {
  display: grid;
  grid-gap: 48px;
  padding: 32px;
}

.block {
  overflow: hidden;
}
